<template>
  <p class="text-xl font-bold text-black/70 shrink-0">
    ₴ {{ applySale(product) * amount }}
    <span class="line-through decoration-[#black] decoration-2 text-sm" v-if="store.site.globalSale || product.sale">₴ {{ product.price }}</span>
  </p>
</template>

<script setup>
import { useStore } from '@/stores/main'

const { applySale } = useUtils()
const store = useStore()

const props = defineProps({
  product: {
    type: Object,
    reqired: true
  },
  amount: {
    type: Number,
    default: 1
  },
})

</script>
